import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import "./menu.css";
import zuidzorglogo from "../images/zuidzorg.logo.png";
import Clock from "./clock";

const Menu = () => {
  const { isAuthenticated, userData, logout } = useAuth();
  const [greeting, setGreeting] = useState("");
  const [department, setDepartment] = useState("");
  const [title, setTitle] = useState("");

  const name = isAuthenticated ? userData.given_name : "";
  const isDelegatedAdmin = isAuthenticated ? userData.isDelegatedAdmin : false;
  const isAdmin = isAuthenticated ? userData.isAdmin : false;
  const email = isAuthenticated ? userData.email : "";

  const tooltesters = JSON.parse(process.env.REACT_APP_TOOLTESTERS || "[]");

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    const updateGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        setGreeting("Goedemorgen");
      } else if (hour < 18) {
        setGreeting("Goedemiddag");
      } else {
        setGreeting("Goedenavond");
      }
    };

    updateGreeting();

    const intervalId = setInterval(updateGreeting, 60000);

    // Fetch and set the department and title
    if (isAuthenticated && userData) {
      // Assuming userData.organizations[0].department is available and correct
      setDepartment(userData.organizations?.[0]?.department || "");
      setTitle(getUserFunction(userData));
    }

    return () => clearInterval(intervalId);
  }, [isAuthenticated, userData]);

  const getUserFunction = (userData) => {
    if (
      userData &&
      userData.organizations &&
      userData.organizations.length > 0
    ) {
      return userData.organizations[0].title;
    }
    return "";
  };

  return (
    <div className="menu">
      <div className="menu-bar">
        <div className="logo-container">
          <span>
            <img className="zzlogo" src={zuidzorglogo} alt="Logo" />
          </span>
          <span className="name">
            <h2>
              {greeting}&nbsp;
              {name}
            </h2>
          </span>
        </div>
        <ul>
          <li>
            <Link to="/">Apps</Link>
          </li>
          <li>
            <Link to="/account">Account</Link>
          </li>
          <li>
            <Link to="/help">Help</Link>
          </li>
          {isAuthenticated && department.includes("MNT") && (
            <li>
              <Link to="/MNT">MNT</Link>
            </li>
          )}
          <li>
            {isAuthenticated ? (
              <Link onClick={handleLogout}>Uitloggen</Link>
            ) : (
              <Link to="/">Inloggen</Link>
            )}
          </li>
          {(isAdmin || isDelegatedAdmin) && (
            <li>
              <Link to="/tools">Tools</Link>
            </li>
          )}
          <li>
            {isAuthenticated && tooltesters.includes(email) && (
              <Link to="/inschrijven">Device Wissel</Link>
            )}
          </li>
          <li className="floatright">
            <Clock />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Menu;
