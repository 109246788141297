import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import "./home.css";

// Image imports for various application tiles
import gmail_tile from "../images/gmail.png";
import drive_tile from "../images/drive.png";
import calendar_tile from "../images/calendar.png";
import topdesk_tile from "../images/topdesk.png";
import puur_tile from "../images/PUUR.png";
import remote_tile from "../images/remote.png";
import ziezo_tile from "../images/ziezo.png";
import vilans_tile from "../images/vilans.png";
import afas_tile from "../images/afas.png";
import kpn_tile from "../images/kpn.png";
import carefriend_tile from "../images/carefriend.png";
import venvn_tile from "../images/venvn.png";
import groups_tile from "../images/groups.png";
import stube_tile from "../images/stube.png";
import xpert_tile from "../images/xpertsuite.png";
import spendcloud_tile from "../images/spendcloud.png";
import gadmin_tile from "../images/gadmin.png";
import csearch_tile from "../images/csearch.png";
import qurentis_tile from "../images/qurentis.png";
import esculine_tile from "../images/esculine.png";
import aws_tile from "../images/appstream.png";
import zkde_tile from "../images/ztb.png";
import iam_tile from "../images/iam.png";
import hoogland_tile from "../images/hoogland-medical.png";
import bosman_tile from "../images/bosman.png";
import portero_tile from "../images/portero.png";
import vgdb_tile from "../images/vgdb.png";
import omnibox_tile from "../images/omnibox.png";

const callCloudFunction = async (userEmail) => {
  try {
    const userData = await fetch(process.env.REACT_APP_FETCH_USER_DETAILS, {
      method: "POST",
      body: JSON.stringify({ userKey: userEmail, projection: "full" }),
    });
    const googleUser = await userData.json();
    return googleUser.user;
  } catch (error) {
    console.error("Error calling cloud function:", error);
    throw error;
  }
};

const Home = () => {
  const navigate = useNavigate();
  const { isAuthenticated, login, updateUserData, userData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [department, setDepartment] = useState("");
  const [userFunction, setUserFunction] = useState("");
  const [userOu, setUserOu] = useState("");
  const [isDelegatedAdmin, setDelegatedAdminRole] = useState("");
  const [isAdmin, setAdminRole] = useState("");

  useEffect(() => {
    const storedUser = localStorage.getItem("isAuthenticated");
    const storedUserDetails = localStorage.getItem("userdetails");

    if (storedUser && !isAuthenticated) {
      const user = JSON.parse(storedUser);
      login(user);

      // Update user details using 'storedUserDetails' if available
      const userDetails = storedUserDetails
        ? JSON.parse(storedUserDetails)
        : null;
      updateUserData(userDetails || user);
    }

    if (userData) {
      const userOu = userData.orgUnitPath;
      setUserOu(userOu);
      const isDelegatedAdmin = userData.isDelegatedAdmin;
      setDelegatedAdminRole(isDelegatedAdmin);
      const isAdmin = userData.isAdmin;
      setAdminRole(isAdmin);
      const userDepartment = getDepartment(userData);
      setDepartment(userDepartment);
      const userFunction = getUserFunction(userData);
      setUserFunction(userFunction);
    }
  }, [login, updateUserData, isAuthenticated, userData, forceUpdate]);

  const handleLoginSuccess = async (credentialResponse) => {
    setLoading(true);

    try {
      const credentialResponseDecoded = jwtDecode(
        credentialResponse.credential
      );
      const userEmail = credentialResponseDecoded.email;
      const additionalUserData = await callCloudFunction(userEmail);
      const updatedUserData = {
        ...credentialResponseDecoded,
        ...additionalUserData,
      };

      login(updatedUserData);

      localStorage.setItem("isAuthenticated", JSON.stringify(updatedUserData));
      localStorage.setItem("userdetails", JSON.stringify(updatedUserData));
      reCheckUserDetails(userEmail, setForceUpdate);
    } catch (error) {
      console.error("Error fetching additional user data:", error);
    }

    setLoading(false);

    navigate("/");
  };

  const reCheckUserDetails = (userEmail, setForceUpdate) => {
    const fetchAndSetUserDetails = async () => {
      try {
        const refreshedUserData = await callCloudFunction(userEmail);
        updateUserData(refreshedUserData);
        setForceUpdate((prev) => !prev);
      } catch (error) {
        console.error("Error refreshing user details:", error);
      }
    };

    clearInterval(reCheckUserDetails.intervalId);
    reCheckUserDetails.intervalId = setInterval(
      fetchAndSetUserDetails,
      3600000
    );
    return reCheckUserDetails.intervalId;
  };

  useEffect(() => {}, [forceUpdate]);

  const getDepartment = (userData) => {
    if (
      userData &&
      userData.organizations &&
      userData.organizations.length > 0
    ) {
      return userData.organizations[0].department;
    }
    return "";
  };

  const getUserFunction = (userData) => {
    if (
      userData &&
      userData.organizations &&
      userData.organizations.length > 0
    ) {
      return userData.organizations[0].title;
    }
    return "";
  };

  const all_zuidzorg_access = JSON.parse(
    process.env.REACT_APP_ALL_ZUIDZORG || "[]"
  );
  const all_thuiscoaching_access = JSON.parse(
    process.env.REACT_APP_ALL_THUISCOACHING || "[]"
  );
  const all_wijkzorg_access = JSON.parse(
    process.env.REACT_APP_ALL_WIJKZORG || "[]"
  );
  const all_vastgoed_access = JSON.parse(
    process.env.REACT_APP_ALL_VASTGOED || "[]"
  );
  const all_stichting_access = JSON.parse(
    process.env.REACT_APP_ALL_STICHTING || "[]"
  );

  const business_controller_access = JSON.parse(
    process.env.REACT_APP_BC || "[]"
  );

  const or_access = JSON.parse(process.env.REACT_APP_SOR || "[]");

  const topdesk_access = JSON.parse(process.env.REACT_APP_TOPDESK || "[]");
  const broadsoft_access = JSON.parse(process.env.REACT_APP_BROADSOFT || "[]");
  const appstream_access = JSON.parse(process.env.REACT_APP_APPSTREAM || "[]");
  const xpert_suite_access = JSON.parse(
    process.env.REACT_APP_XPERTSUITE || "[]"
  );
  const ztb_access = JSON.parse(process.env.REACT_APP_ZTB || "[]");
  const iam_access = JSON.parse(process.env.REACT_APP_IAM || "[]");
  const teams_stichting_access = JSON.parse(
    process.env.REACT_APP_ALL_TC_TEAM_STICHTING || "[]"
  );

  const thuiscoaching_teammanagement = JSON.parse(
    process.env.REACT_APP_ALL_TC_TEAM_MANAGEMENT || "[]"
  );
  const thuiscoaching_bedrijfsvoering = JSON.parse(
    process.env.REACT_APP_ALL_TC_TEAM_BEDRIJFSVOERING || "[]"
  );

  const LoadingSpinner = () => (
    <div className="content-loading">
      <h2 className="pagename">Tegels laden..</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <div
          style={{
            border: "4px solid #013e74",
            borderRadius: "50%",
            borderTop: "4px solid #afca09",
            width: "60px",
            height: "60px",
            animation: "spin 0.9s linear infinite",
          }}
        ></div>
      </div>
    </div>
  );

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENTID}>
      <div>
        <hr className="border"></hr>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {isAuthenticated ? (
              <div className="content">
                <h2 className="pagename">ZuidZorg Apps</h2>
                <div className="grid-container grid-container--fill">
                  {userData.isMailboxSetup && (
                    <>
                      <div className="grid-element">
                        <a
                          href="https://mail.google.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={gmail_tile} alt="Gmail" />
                          Gmail
                        </a>
                      </div>
                      <div className="grid-element">
                        <a
                          href="https://drive.google.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={drive_tile} alt="Drive" />
                          Drive
                        </a>
                      </div>
                      <div className="grid-element">
                        <a
                          href="https://calendar.google.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={calendar_tile} alt="Agenda" />
                          Agenda
                        </a>
                      </div>
                      <div className="grid-element">
                        <a
                          href="https://groups.google.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={groups_tile} alt="Groepen" />
                          Groepen
                        </a>
                      </div>
                      <div className="grid-element">
                        <a
                          href="https://cloudsearch.google.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={csearch_tile} alt="Cloud Search" />
                          Cloud Search
                        </a>
                      </div>
                    </>
                  )}
                  <div className="grid-element">
                    <a
                      href="https://remotedesktop.google.com/support"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={remote_tile} alt="Chrome Remote" />
                      Chrome Remote
                    </a>
                  </div>
                  {all_zuidzorg_access.some((ou) => userOu.startsWith(ou)) && (
                    <div className="grid-element">
                      <a
                        href="https://www.vilanskickprotocollen.nl"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={vilans_tile} alt="Vilans Protocollen" />
                        Vilans Protocollen
                      </a>
                    </div>
                  )}
                </div>
                <h2 className="pagename">Mijn Apps</h2>
                <div className="grid-container grid-container--fill">
                  {all_thuiscoaching_access.some((ou) =>
                    userOu.startsWith(ou)
                  ) && (
                    <div className="grid-element">
                      <a
                        href="https://zuidzorg.carefriend.nl/login"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={carefriend_tile} alt="CareFriend" />
                        CareFriend
                      </a>
                    </div>
                  )}
                  {all_thuiscoaching_access.some((ou) =>
                    userOu.startsWith(ou)
                  ) && (
                    <div className="grid-element">
                      <a
                        href="https://drive.google.com/drive/folders/0ALG7rNov3r6AUk9PVA"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={drive_tile}
                          alt="ZieZo Drive Thuis Coaching"
                        />
                        ZieZo Thuiscoaching
                      </a>
                    </div>
                  )}
                  {all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) && (
                    <div className="grid-element">
                      <a
                        href="https://zuidzorg-zorgdossier.ecare.nl"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={puur_tile} alt="Puur Zorgdossier" />
                        PUUR Zorgdossier
                      </a>
                    </div>
                  )}
                  {(business_controller_access.includes(userFunction) ||
                    or_access.includes(userFunction) ||
                    all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) ||
                    all_vastgoed_access.some((ou) => userOu.startsWith(ou)) ||
                    teams_stichting_access.includes(department) ||
                    teams_stichting_access.includes(userFunction)) && (
                    <div className="grid-element">
                      <a
                        href="https://zuidzorg.ecare.nl"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={puur_tile} alt="Puur Administratie" />
                        PUUR Administratie
                      </a>
                    </div>
                  )}
                  {all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) && (
                    <div className="grid-element">
                      <a
                        href="https://mijn.hooglandmedical.nl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={hoogland_tile} alt="Hoogland Medical" />
                        Hoogland Medical
                      </a>
                    </div>
                  )}
                  {(thuiscoaching_teammanagement.includes(department) ||
                    thuiscoaching_bedrijfsvoering.includes(department) ||
                    appstream_access.includes(department) ||
                    appstream_access.includes(userFunction) ||
                    all_vastgoed_access.some((ou) => userOu.startsWith(ou)) ||
                    teams_stichting_access.includes(department) ||
                    teams_stichting_access.includes(userFunction)) && (
                    <div className="grid-element">
                      <a
                        href="https://accounts.google.com/o/saml2/initsso?idpid=C01posjoi&spid=322081757974&forceauthn=false"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={aws_tile} alt="AppStream" />
                        AppStream
                      </a>
                    </div>
                  )}
                  {all_vastgoed_access.some((ou) => userOu.startsWith(ou)) && (
                    <div className="grid-element">
                      <a
                        href="https://mijn.vgdb.nl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={vgdb_tile} alt="Vastgoeddatabank" />
                        Vastgoeddatabank
                      </a>
                    </div>
                  )}
                  {all_vastgoed_access.some((ou) => userOu.startsWith(ou)) && (
                    <div className="grid-element">
                      <a
                        href="https://zuidzorgvastgoed.omniboxx.nl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={omnibox_tile} alt="omniboxx." />
                        Omniboxx
                      </a>
                    </div>
                  )}
                  {(all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) ||
                    or_access.includes(userFunction)) && (
                    <div className="grid-element">
                      <a
                        href="https://ziezo.zuidzorg.nl"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={ziezo_tile} alt="ZieZo" />
                        ZieZo
                      </a>
                    </div>
                  )}
                  {all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) && (
                    <div className="grid-element">
                      <a
                        href="https://www.mijnbosman.com/Home/Inloggen"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={bosman_tile} alt="Bosman" />
                        Bosman
                      </a>
                    </div>
                  )}
                  {department && ztb_access.includes(department) && (
                    <div className="grid-element">
                      <a
                        href="https://ztb.zkde.nl/login.php"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={zkde_tile} alt="ZKDE" />
                        ZKDE
                      </a>
                    </div>
                  )}
                  {department && ztb_access.includes(department) && (
                    <div className="grid-element">
                      <a
                        href="https://aanvraag.ciz.nl/isa-zorgaanmelder-web/login/auth"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={portero_tile} alt="Portero" />
                        Portero
                      </a>
                    </div>
                  )}
                  {(all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) ||
                    all_thuiscoaching_access.some((ou) =>
                      userOu.startsWith(ou)
                    ) ||
                    teams_stichting_access.includes(department) ||
                    teams_stichting_access.includes(userFunction)) && (
                    <div className="grid-element">
                      <a
                        href="https://www.venvn.nl" //https://mijn.venvn.nl/SignIn?returnUrl=/
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={venvn_tile} alt="V&VN" />
                        V&VN
                      </a>
                    </div>
                  )}
                  {(all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) ||
                    teams_stichting_access.includes(department) ||
                    teams_stichting_access.includes(userFunction)) && (
                    <div className="grid-element">
                      <a
                        href="https://platform.esculine.nl/zuidzorg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={esculine_tile} alt="Esculine" />
                        EscuLine
                      </a>
                    </div>
                  )}
                  {(all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) ||
                    all_thuiscoaching_access.some((ou) =>
                      userOu.startsWith(ou)
                    ) ||
                    teams_stichting_access.includes(department) ||
                    teams_stichting_access.includes(userFunction)) && (
                    <div className="grid-element">
                      <a
                        href="https://zuidzorg.studytube.nl/discover"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={stube_tile} alt="Leerhuis ZuidZorg" />
                        Leerhuis ZuidZorg
                      </a>
                    </div>
                  )}
                  {(all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) ||
                    all_vastgoed_access.some((ou) => userOu.startsWith(ou)) ||
                    thuiscoaching_teammanagement.includes(department) ||
                    thuiscoaching_bedrijfsvoering.includes(department) ||
                    teams_stichting_access.includes(department) ||
                    teams_stichting_access.includes(userFunction)) && (
                    <div className="grid-element">
                      <a
                        href="https://zuidzorg.spend.cloud/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={spendcloud_tile} alt="Spendcloud" />
                        Spendcloud
                      </a>
                    </div>
                  )}
                  {(thuiscoaching_teammanagement.includes(department) ||
                    thuiscoaching_bedrijfsvoering.includes(department)) && (
                    <div className="grid-element">
                      <a
                        href="https://qworkx1.qurentis.nl/guacamole/#/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={qurentis_tile} alt="Qurentis" />
                        Qurentis
                      </a>
                    </div>
                  )}
                  {topdesk_access.includes(department) && (
                    <div className="grid-element">
                      <a
                        href="https://zuidzorg.topdesk.net/tas/secure/login/saml"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={topdesk_tile} alt="Topdesk" />
                        Topdesk
                      </a>
                    </div>
                  )}
                  {(isDelegatedAdmin || isAdmin) && (
                    <div className="grid-element">
                      <a
                        href="https://admin.google.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={gadmin_tile} alt="Google Admin" />
                        Google Admin
                      </a>
                    </div>
                  )}
                  {(isDelegatedAdmin || isAdmin) && (
                    <div className="grid-element">
                      <a
                        href="https://zuidzorg.iam-adapta.nl"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={iam_tile} alt="IAM Adapta" />
                        IAM Adapta
                      </a>
                    </div>
                  )}
                  {(xpert_suite_access.includes(userFunction) ||
                    xpert_suite_access.includes(department)) && (
                    <div className="grid-element">
                      <a
                        href="https://apps02.xpertsuite.nl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={xpert_tile} alt="Xpert Suite" />
                        Xpert Suite
                      </a>
                    </div>
                  )}
                  {broadsoft_access.includes(department) && (
                    <div className="grid-element">
                      <a
                        href="https://webapps.kpneen.nl/callcenter/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={kpn_tile} alt="Broadsoft Callcenter" />
                        Broadsoft Callcenter
                      </a>
                    </div>
                  )}
                  {(all_thuiscoaching_access.some((ou) =>
                    userOu.startsWith(ou)
                  ) ||
                    all_wijkzorg_access.some((ou) => userOu.startsWith(ou)) ||
                    all_vastgoed_access.some((ou) => userOu.startsWith(ou)) ||
                    teams_stichting_access.includes(department) ||
                    teams_stichting_access.includes(userFunction) ||
                    or_access.includes(userFunction)) && (
                    <div className="grid-element">
                      <a
                        href="https://login.afasonline.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={afas_tile} alt="AFAS Insite" />
                        AFAS Insite
                      </a>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="content">
                <h2 className="pagename">Inloggen met Google</h2>
                <div className="loginbutton">
                  <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    cookiePolicy={"single_host_origin"}
                    type="standard"
                    size="medium"
                    shape="circle"
                    width="200px"
                    ux_mode="popup"
                    theme="filled_blue"
                    text="continue_with"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </GoogleOAuthProvider>
  );
};

export default Home;
