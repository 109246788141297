import React from "react";
import "./home.css";

const Inschrijven= () => {
  return (
    <div className="iframe">
      <hr className="border"></hr>
      <iframe
        // src="http://localhost:3001"
        src="https://inschrijven-device-wissel.web.app/"
        title="Inschrijven Device Wissel"
        width="100%"
        height="100%"
        style={{ border: "none" }}
      />
    </div>
  );
};

export default Inschrijven;
