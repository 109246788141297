import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Menu from "./components/Menu";
import Home from "./components/Home";
import Contact from "./components/Contact";
import About from "./components/About";
import Tools from "./components/Tools";
import MNT from "./components/MNT";
import Inschrijven from "./components/Inschrijven";
import { useAuth } from "./components/AuthContext";

const App = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/account"
          element={isAuthenticated ? <About /> : <Navigate to="/" />}
        />
        <Route
          path="/help"
          element={isAuthenticated ? <Contact /> : <Navigate to="/" />}
        />
        <Route
          path="/tools"
          element={isAuthenticated ? <Tools /> : <Navigate to="/" />}
        />
        <Route
          path="/mnt"
          element={isAuthenticated ? <MNT /> : <Navigate to="/" />}
        />
        <Route
          path="/inschrijven"
          element={isAuthenticated ? <Inschrijven /> : <Navigate to="/" />} // Deze route toont de externe app in een iframe
        />
      </Routes>
    </>
  );
};

export default App;
